export const Modal = props => {
  const { handleClose, open, arialabel = `modal`, ariadescribe = `modal-description`, className, widthContainer = `80%`, heightContainer = `auto`, mobile = ``, widthMobile = 100, heightMobile = 100 } = props

  const handleBackdropClick = e => {
    if (e.target.id === `modal-backdrop`) {
      handleClose()
    }
  }

  return open && (
    <div
      id="modal-backdrop" aria-labelledby={arialabel}
      aria-describedby={ariadescribe}
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 overflow-hidden`}
      style={{ zIndex: 1601 }}
      onClick={handleBackdropClick}
    >
      <div
        {...props}
        className={`${className} bg-white rounded-lg shadow-lg p-3 transform overflow-y-auto max-h-auto`}
        style={{
          width: mobile ? `${widthMobile}dvw` : widthContainer,
          height: mobile ? `${heightMobile}dvh` : heightContainer
        }}
        onClick={e => e.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  )
}
