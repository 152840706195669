import { TWDivider } from "@/components/tailwind/Divider"
import React from "react"
export const SumaryForm = props => {
  const { labela, labelb, textcolora, textcolorb } = props

  return (
    <div className="flex justify-between items-center w-full">

      <p
        className="text-base font-semibold whitespace-nowrap"
        style={{ color: textcolora }}
      >
        {labela}
      </p>

      <TWDivider color="gray" my={0} mx={5} dotted/>

      <p
        className="text-base font-semibold whitespace-nowrap ml-2"
        style={{ color: textcolorb }}
      >
        {labelb}
      </p>
    </div>
  )
}

export const SumaryOrder = props => {
  const {
    labela, labelb, textcolora, textcolorb, upper, weight, underline
  } = props
  return (
    <div className="w-full flex justify-between items-baseline">
      <p style={{ color: textcolora, fontWeight: weight ?? 600 }} className={`text-nowrap w-fit text-base ${underline ? `underline` : ``} ${upper ? `capitalize` : ``}`}>
        {labela}
      </p>
      <TWDivider color="lightGray" my={0} mx={5} dotted/>
      <p style={{ color: textcolorb ?? textcolora, fontWeight: weight ?? 600 }} className={`text-nowrap w-fit text-base ${underline ? `underline` : ``}`}>
        {labelb}
      </p>
    </div>
  )
}
