import { DesktopFilters } from "@/app/(web)/_components/filters/desktop"
import LoaderBar from "@/components/tailwind/LoaderBar"
import { ToolsContext } from "@/context/tools/tools"
import { useContext, useMemo } from "react"
import { BoxCategories } from "./BoxCategories"
import { MobileFilters } from "./MobileFilters"
import { RemoveFilter } from "./RemoveFilter"

export const FilterMenu = props => {
  const { handleRemove, category, mobile, chipcategories, result, filters, meta, colors, search, loading } = props
  const { tools: { texts }} = useContext(ToolsContext)
  const pageTexts = useMemo(() => texts.catalogue_page, [texts])
  return (
    <>
      {chipcategories.length > 0 &&
        <div className="w-full py-[20px] tablet:py-[10px]" id="desktop-filter">
          <BoxCategories {...{ category, chipcategories, width: 35, webdata: props.catalogue.webdata, colors }} />
        </div>
      }
      <div style={{ position: `sticky`, top: 65, zIndex: 10 }} className="hidden tablet:block pt-2 border-b-[1px] border-neutral-300 bg-white">
        <div className="flex">
          <div
            className="rounded-full p-[1px] px-2 bg-slate-100 text-gray-700 text-sm border-[1px] mr-1"
            style={{ borderColor: colors.lightGrey3 }}
          >
            {`${result} ${pageTexts?.results ?? ``}`}
          </div>
          <RemoveFilter {...{ mobile, filters, handleRemove, colors, search }} />
        </div>
        <DesktopFilters {...props} {...{ sticky: false, MOBILE: mobile, fill: `false`, meta, colors, pageTexts }} />
        {loading && <LoaderBar color={colors.tildMenu}/>
        }
      </div>
      <div style={{ position: `sticky`, top: 14, zIndex: 10 }} className="block tablet:hidden">
        <div className="flex py-1">
          <div
            className="rounded-full p-[1px] px-2 bg-slate-100 text-gray-700 text-sm border-[1px] mr-1"
            style={{ borderColor: colors.lightGrey3 }}
          >
            {`${result} ${pageTexts?.results ?? ``}`}
          </div>
        </div>
        <MobileFilters {...props} {...{ mob: `true`, pageTexts }}>
          {category && (
            <>
              {chipcategories.length > 0 && (
                <p>
                  {pageTexts?.category ?? ``}
                </p>
              )}
              <div>
                <BoxCategories {...{ colors, category, chipcategories }} />
              </div>
            </>
          )}
        </MobileFilters>
        {loading && <LoaderBar color={colors.tildMenu}/>
        }
      </div>
    </>
  )
}
