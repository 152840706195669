/* eslint-disable @next/next/no-img-element */
import { handleKeyRandom } from '@/utils/functions'
import { IoMdArrowDropup } from 'react-icons/io'

export const RadioBusinessOney = props => {
  const {
    setter, arr, value, businessCode, colors
  } = props

  return (
    <div id={`selector-${value}`} className="flex flex-row justify-evenly mt-2">
      {arr.map((x, i) => (
        <div key={handleKeyRandom(`${value}-${i}`, i)} id={x} className="flex flex-col items-center">
          <label className="flex items-center cursor-pointer w-16 h-16">
            <input
              type="radio"
              name={`selector-${value}-channel`}
              value={i}
              onClick={() => setter(x)}
              className="hidden peer"
            />
            <img
              src={`/img/payment-icons/oney/checked/${x}.svg`}
              alt={`${x} icon`}
              className="w-32 h-32"
            />
          </label>
          {x === businessCode && (
            <IoMdArrowDropup color={colors.oney} fontSize={28} className="mt-1" />
          )}
        </div>
      ))}
    </div>
  )
}
