/* eslint-disable @next/next/no-img-element */
import { handleKeyRandom } from '@/utils/functions'
import IconsSVG from "@/utils/icons/IconsSVG"
import React, { useId } from 'react'

export const BusinessCodeInfo = props => {
  const { info, texts } = props
  const keyId = useId()
  return (
    <div>
      <table>
        <thead>
          <tr className="p-2">
            <td className="ms-2 pe-2" align="center" style={{ fontWeight: 700 }}>
              {texts.code}
            </td>
            <td align="center" className="pe-2" style={{ fontWeight: 700 }}>
              {texts.installments}
            </td>
            <td align="center" className="pe-2" style={{ fontWeight: 700 }}>
              {texts.minPrice}
            </td>
            <td align="center" className="pe-2" style={{ fontWeight: 700 }}>
              {texts.maxPrice}
            </td>
          </tr>
        </thead>
        <tbody>
          {info.map((row, i) => {
            return (
              <tr key={handleKeyRandom(keyId, i)}>
                <td className="oney" align="center">
                  <img className="h-8 w-8"
                    src={`/img/payment-icons/oney/checked/${row.business_transaction_code}.svg`}/>
                </td>
                <td align="center">
                  {row.minimum_number_of_instalments}
                </td>
                <td align="center">
                  {`${row.minimum_selling_price}€`}
                </td>
                <td align="center">
                  {`${row.maximum_selling_price}€`}
                </td>
              </tr>)
          })}
        </tbody>
      </table>
    </div>
  )
}
