'use client'
import { TWButton } from "@/components/tailwind/Button"
import LoaderBar from "@/components/tailwind/LoaderBar"
import { addrToString } from "@/utils/functions"
import { useState } from "react"
import { SiHomeassistantcommunitystore } from "react-icons/si"
import { DropDownArrows } from "../../../basket/subcomponent/components/subcomponents/DropDownArrows"
import LinkWrapper from "../../../carousel/subcomponents/LinkWrapper"

export const ShopsModal = props => {
  const {
    globalTexts, store_availability, stores, stocks, colors, loading = false, check_stock = ``, sections
  } = props
  const [ open, setOpen ] = useState(false)
  const { total, ...shops } = stocks
  return (
    <>
      <TWButton {...{ colors }} onClick={() => setOpen(!open)} type="text">
        <SiHomeassistantcommunitystore className="size-4 mr-1 text-black"/>
        <p className="text-[15px] font-semibold hover:underline">{store_availability}</p>
        <DropDownArrows {...{ open }} className="ml-1" />
      </TWButton>
      {open &&
      <div className="w-full flex">
        <div className="border rounded-xl py-2 px-4 cursor-default">
          {loading ? <div className="min-h-24">
            <div>
              <LoaderBar/>
              <p className="text-center">{check_stock}</p>
            </div>
          </div> :
            Object.entries(sections.warehouse ? stocks : shops).map(([ shop, value ], i) => {
              const selStore = stores.find(store => store?.storeName?.toLowerCase() === shop?.toLowerCase())
              const availText = value?.toLowerCase() === `disponible` ? globalTexts.available : globalTexts.not_available
              return <div key={`shop${i}`} >
                <span className="flex items-center">
                  <p className={`uppercase font-bold text-sm`}>{selStore?.storeName ?? `almacén`}</p>
                  <p className={`mx-2 lowercase font-bold text-sm ${value?.toLowerCase() === `disponible` ? `text-green-600` : `text-red-600`}`}>{availText}</p>
                </span>
                {selStore && <LinkWrapper url={selStore?.mapsLocation} target="_blank" passHref>
                  <p className={`text-[13px] ml-3 text-neutral-500 hover:underline`}>{addrToString(selStore ?? ``)}</p>
                </LinkWrapper>}

              </div>
            })}
        </div>
      </div>
      }
    </>
  )
}
