import { handleKeyRandom } from '@/utils/functions'
import React from 'react'

export const RadioSelector = props => {
  const {
    setter, arr, value, add, colors
  } = props
  const handleSelect = i => {
    setter(i + add)
  }
  return (
    <div
      aria-labelledby={`selector-${value}`}
      name={`selector-${value}-channel`}
      defaultValue={0}
      className="flex"
      style={{
        flexDirection: `row`,
        justifyContent: `space-evenly`
      }}
    >
      {arr.map((x, i) => (
        <div
          key={handleKeyRandom(`${value}-${i}`, i)}
          id={x}
          className="ms-1 me-2"
        >
          <input
            type="radio"
            name="selector-radio"
            value={i}
            onClick={() => handleSelect(i)}
            style={{
              color: colors.color,
            }}
          />
          <span className="ms-1">{x}</span>
        </div>

      ))}
    </div>
  )
}
